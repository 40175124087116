/** BASE **/
@import "./base/normalize.css";

// UTILS
@import "./utils/variables.scss";
@import "./utils/mixins.scss";

// LIBS
@import "./libs/swiper.scss";

/** GLOBALS **/
@import "./base/global.scss";

/** MIXINS **/

/** COMPONENTS **/
@import "./components/header.scss";
@import "./components/box-available.scss";
@import "./components/loader.scss";
@import "./components/slider-cursor.scss";

/** Layout **/
*::selection {
    background: $main;
    color: white;
}

* {
    -webkit-tap-highlight-color: transparent;
}

/** PAGES **/
@import "./pages/home.scss";
@import "./pages/product-single.scss";
@import "./pages/about.scss";
@import "./pages/404.scss";
@import "./pages/contact.scss";
