.form-is-visible.page-contact {
    .bg-name-title {
        @include hidden();
        transition: ease 0.4s;
        transition-property: opacity, visibility;
    }
}

.page-contact {
    padding-top: 36px;
    max-width: 1200px;
    margin: 0 auto;

    @include above("xxl") {
        padding-top: 0;
        height: 100vh;
        position: relative;
        display: flex;
        flex-direction: column;
        padding-top: 80px;
    }
    @include above("xxxxl") {
        max-width: 1640px;
    }

    .bg-name-title {
        position: fixed;
        z-index: -1;
    }

    &__header {
        &-title {
            font-size: 120px;
            font-family: $font-title;
            font-weight: 400;
            text-align: center;
            line-height: 1;
            @include below("lg") {
                padding-top: 44px;
            }
        }

        &-email {
            position: relative;

            &-button {
                color: #000000
            }

            &-tooltip {
                @include hidden();
                position: absolute;
                left: 50%;
                transform: translate(-50%, 0);
                margin-top: 4px;
                transition: ease 0.15s; // LINKED TO JS CopyEmail.js
                transition-property: opacity, visibility;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                pointer-events: none;

                span {
                    position: absolute;
                    left: 10px;
                    top: 20px;
                    white-space: nowrap;
                    color: #fff;
                    font-size: 13px;
                    width: 82px;
                    text-align: center;
                }
            }

            &.copied &-tooltip {
                svg path {
                    fill: $green;
                }
            }

            &:hover &-tooltip,
            &.copied-in &-tooltip {
                @include visible();
            }
        }

        &-links {
            margin-top: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 15px;
        }

        &-separator {
            margin: 0 16px;
            svg {
                width: auto;
            }
        }

        &-link {
            display: inline-flex;
            align-items: center;

            &-icon {
                margin-left: 4px;
                transition: transform ease 0.35s;
                svg {
                    width: auto;
                }
            }

            @include above("lg") {
                &:hover & {
                    &-icon {
                        transform: translate(25%, -25%);
                    }
                }
            }
        }
    }

    &__form {
        display: flex;
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
        margin-top: 40px;
        flex-wrap: wrap;
        position: relative;
        z-index: 3;

        @include above("xl") {
            height: calc(100% - 80px - 165px - 40px);
            align-items: center;
        }

        &-subjects {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: flex-end;
            position: relative !important;
            top: unset !important;

            &.has-selected {
                .blob {
                    svg path {
                        fill: $secondary;
                    }
                }
            }

            &-item {
                display: block;
                color: #fff;
                position: absolute !important;

                &.is-selected {
                    .blob {
                        svg path {
                            fill: $main;
                        }
                    }
                }

                &-label {
                    width: 50%;
                    display: inline-block;
                    line-height: 130%;

                    @include below("lg") {
                        width: 75%;
                    }
                }

                .blob {
                    z-index: -1;
                }
            }
        }

        &-form {
            margin-left: auto;
            display: flex;
            flex-direction: column;

            // Form is ready
            &.ready .page-contact__form {
                &-submit {
                    pointer-events: initial;

                    &-arrow path {
                        fill: #fff;
                    }

                    .blob {
                        &__svg path {
                            fill: $main;
                        }
                    }
                }
            }

            &.loading .page-contact__form {
                &-submit {
                    pointer-events: none;

                    &-arrow path {
                        fill: transparent;
                    }

                    .blob {
                        &__svg {
                            transform: scale(0.7);
                        }

                        &__svg-extra {
                            stroke-dasharray: 250;
                            stroke-dashoffset: 250;
                            -webkit-animation: progress 1.5s ease-out forwards;
                            animation: progress 1.5s ease-out forwards;

                            @-webkit-keyframes progress {
                                to {
                                    stroke-dashoffset: 0;
                                }
                            }

                            @keyframes progress {
                                to {
                                    stroke-dashoffset: 0;
                                }
                            }
                        }
                    }
                }
            }

            &.submitted .page-contact__form {
                &-input {
                    input,
                    textarea,
                    select {
                        color: $secondary;
                        pointer-events: none;
                    }

                    &-bg {
                        svg {
                            stroke: $secondary;
                        }
                    }
                }

                &-submit {
                    pointer-events: none;

                    &-arrow path {
                        fill: transparent;
                    }

                    &-ok {
                        opacity: 1;
                        transform: translate(-50%, -50%) scale(1);
                    }

                    .blob {
                        &__svg-extra {
                            transform: scale(0.5);
                            z-index: -1;
                        }

                        &__svg {
                            transform: scale(1);

                            path {
                                fill: $green;
                                stroke: transparent;
                            }
                        }
                    }
                }
            }
        }

        &-input {
            position: relative;
            overflow: hidden;
            margin-bottom: 22px;

            &.textarea {
                margin-bottom: 16px;
            }

            select {
                appearance: none;
                -webkit-appearance: none;
                -moz-appearance: none;
                color: $secondary;

                option {
                    color: #000;
                }
            }

            input,
            textarea,
            select {
                @include absolute-full();
                font-size: 15px;
                padding-left: 16px;
                border: none;
                background: none;
                outline: none;
                transition: color ease 0.35s;

                &::-webkit-input-placeholder {
                    color: $main;
                }

                &::-moz-placeholder {
                    color: $main;
                }

                &:-ms-input-placeholder {
                    color: $main;
                }

                &::-ms-input-placeholder {
                    color: $main;
                }

                &::placeholder {
                    color: $main;
                }
            }

            textarea {
                padding-top: 10px;
                resize: none;
                max-height: 90%;
                top: 5%;
                width: calc(100% + 15px);
            }

            select {
                width: calc(100% - 20px);
                color: $main;
            }

            &-bg {
                width: 343px;

                svg {
                    max-width: 90vw;
                    stroke: $main;
                    transition: stroke ease 0.35s;
                }
            }
        }

        &-optional-input {
            &-arrow {
                position: absolute;
                top: 21px;
                right: 16px;
            }

            .page-contact__form-input {
                margin-bottom: 0;
            }

            .margin-block {
                height: 22px;
            }
        }

        &-submit {
            display: inline-block;
            margin-left: auto;
            pointer-events: none;
            position: relative;
            width: 64px;
            height: 64px;

            &-icon {
                @include absolute-center();
            }

            &-ok {
                opacity: 0;
                transform: translate(-50%, -50%) scale(0.8);
                transition: ease-out 0.35s;
                transition-property: opacity, transform;
            }

            &-arrow {
                path {
                    fill: $main;
                    transition: fill ease-out 0.35s;
                }
            }

            .blob {
                z-index: -1;

                &.is-hover .blob__svg {
                    transform: scale(1.2);
                    width: red;
                }

                &__svg-extra {
                    transform: scale(0.95);
                    z-index: -3;

                    path {
                        fill: none;
                        stroke-width: 0.5px;
                    }
                }

                &__svg {
                    path {
                        fill: $secondary;
                        stroke-width: 1px;
                    }
                }
            }
        }
    }

    &__socials {
        display: none;
    }

    @include below("xl") {
        &__form {
            flex-direction: column;
            align-items: center;

            &-subjects {
                width: 330px;
                min-width: auto;
            }

            &-form {
                margin-left: 0;
                margin-top: 70px;
            }
        }
    }

    @include below("lg") {
        margin: 0 16px;
        padding-bottom: 32px;

        .bg-name-title {
            display: none;
        }

        &__header {
            &-title {
                font-size: 44px;
            }

            &-links {
                display: none;
            }
        }

        &__form {
            flex-direction: column;

            &-input {
                max-width: 90vw;
            }

            &-form {
                margin-left: 0;
            }

            &-subjects {
                &-item {
                    &-label {
                        font-size: 13px;
                        line-height: 1.4;
                    }
                }
            }
        }

        &__socials {
            margin-bottom: 72px;
            margin-top: 20px;
            display: block;

            &-link {
                display: flex;
                align-items: center;

                &:not(:last-child) {
                    margin-bottom: 8px;
                }

                svg.dot {
                    display: inline-block;
                    width: 6px;
                    margin-right: 8px;
                }

                .arrow {
                    width: 10px;
                    height: 10px;
                    margin-left: 6px;
                    filter: invert(100%);
                }
            }
        }
    }
}
