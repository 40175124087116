
// Utils

@mixin hidden(){
    opacity: 0;
    visibility: hidden;
}

@mixin visible(){
    opacity: 1;
    visibility: visible;
}

@mixin absolute-full(){
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

@mixin absolute-center(){
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}


// Responsive
@mixin below($size){
    @media screen and (max-width: map-get($grid-breakpoints, $size)) {
        @content;
    }
  }
  
  @mixin above($size){
    @media screen and (min-width: map-get($grid-breakpoints, $size)) {
        @content;
    }
}
  