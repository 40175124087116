*,
*:after,
*:before {
    box-sizing: border-box;
}

html,
body,
span,
p {
    font: inherit;
    font-family: Epilogue, "Roboto", sans-serif;
    box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
a,
button {
    padding: 0;
    margin: 0;
    font: inherit;
    font-family: Epilogue, "Roboto", sans-serif;
}

img {
}

img, svg{
    display: block;
    width: 100%;
}

a {
    color: inherit;
    text-decoration: none;
}

li {
    list-style-type: none;
}

button {
    border: none;
    background: none;
    cursor: pointer;
}

html,
body,
.App {
    height: 100vh;
}

svg,
img {
    display: block;
}

body{
    // overscroll-behavior: contain; // not good for accessibility
    font-family: $font-base;
    font-weight: 400;
}

.bg-name-title{
    @include absolute-center();
    font-family: $font-title;
    font-weight: 400;
    font-size: 12vw;
    color: $secondary;
    white-space: nowrap;
    width: 100%;
    text-align: center;
    transform-origin: center center;
    user-select: none;

    @include below("lg"){
        font-size: 50px;
    }
}

.blob{
    @include absolute-full();
    display: flex;
    justify-content: center;
    align-items: center;

    &.is-hover svg{
        transform: scale(.96);
    }

    svg{
        @include absolute-full();
        transition: transform ease-in-out .35s;

        path{
            fill: $main;
            stroke: $main;
            stroke-width: 2px;
            transition: fill ease .35s;
        }
    }
}