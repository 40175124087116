.box-available {
    position: relative;
    display: inline-block;

    height: 30px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    background-image: url("../assets/details/available-background.svg");

    &__text {
        position: absolute;
        font-size: 13px;
        line-height: 13px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        transform: translate(0, 1px);

        &:before {
            content: "";
            width: 8px;
            height: 8px;
            background-color: $red;
            border-radius: 100%;
            margin-right: 4px;
        }
    }

    &.is-available {
        width: 100px;
        .box-available__text:before {
            background-color: $green;
        }
    }
    &.not-available {
        width: 110px;
    }
}
