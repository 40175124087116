body.no-menu .header {
    &__burger {
        .box {
            transform: translate(100%, -100%);
        }

        .line1,
        .line2,
        .line3 {
            transform: translate(100px, -100px);
        }
    }
}

.header {
    position: fixed;
    z-index: 10;
    width: 100%;
    pointer-events: none;

    @include below("md") {
        height: 100%;
    }

    & > * {
        pointer-events: all;
    }

    &__burger {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 10;
        pointer-events: auto;
        label {
            position: relative;
            display: block;
            .box {
                width: 80px;
                height: 80px;
                position: absolute;
                top: 0;
                right: 0;
                transition: all 0.4s ease-in-out;
                .over {
                    transform: translate(2px, -3px);
                }
                .under {
                    transform: translate(0, 0);
                    transition: all 0.3s ease-in-out;
                }
            }
            .line1,
            .line2,
            .line3 {
                width: 24px;
                position: absolute;
                right: 16px;
                transition: all 0.4s ease-in-out;
                user-select: none;
            }
            .line1 {
                top: 16px;
                transform: rotate(0deg);
                transform-origin: top left;
            }
            .line2 {
                top: 24px;
                opacity: 1;
            }
            .line3 {
                top: 32px;
                transform: rotate(0deg);
                transform-origin: bottom right;
            }

            @media (any-hover: hover) {
                &:hover {
                    cursor: pointer;
                    .line1 {
                        transform: translateY(-1px);
                        transition: all 0.3s ease-in-out;
                    }
                    .line3 {
                        transform: translateY(1px);
                        transition: all 0.3s ease-in-out;
                    }
                }
            }
        }
    }
    input {
        display: none;
        &:checked {

            &:not(.animating){
                & + .header__burger {
                    .box {
                        .under {
                            transform: translate(10px, -10px);
                            transition: all 0.3s ease-in-out;
                        }
                    }
                    .line1,
                    .line2,
                    .line3 {
                        transition: all 0.4s ease-in-out;
                    }
                    .line1 {
                        transform: rotate(45deg);
                    }
                    .line2 {
                        max-width: 0;
                        opacity: 0;
                    }
                    .line3 {
                        transform: translateX(-25px) translateY(-2px) rotate(130deg);
                    }
                }
            }
            
            & + .header__burger {

                & + .header__container {
                    transform: translateY(0);
                    transition: 0.6s all ease-in-out;
                    .header__container-over {
                        opacity: 1;
                        transition: opacity 0.3s ease-in-out 0.6s;
                    }
                    .under {
                        transform: translateY(0);
                        transition: 0.4s all ease-in-out 0.4s;
                        @include above("xl") {
                            transform: translateY(2px);
                        }
                        @include above("xxxxl") {
                            transform: translateY(-2px);
                        }
                        @media screen and (min-width: 2400px) {
                            transform: translateY(-4px);
                        }
                    }
                }
            }
        }
    }
    &__container {
        pointer-events: auto;
        transform: translateY(-200%);
        transition: 0.6s all ease-in-out .3s;
        width: calc(100% + 30px);
        margin-left: -15px;
        height: 130px;
        overflow: visible;
        z-index: 0;
        position: relative;

        @include below("md") {
            height: 100%;
        }
        @include above("xxxl") {
            height: 160px;
        }
        @include above("xxxxl") {
            height: 180px;
            transform: translateY(-400%);
        }

        &-over {
            position: relative;
            inset: 0;
            display: flex;
            flex-flow: column-reverse nowrap;
            justify-content: center;
            align-items: center;
            z-index: 7;
            height: calc(100% - 16px);
            width: 100%;
            @include above("md") {
                flex-flow: row nowrap;
            }
            opacity: 0;
            transition: opacity 0.3s ease-in-out;
        }
        &-under {
            width: 100%;
            height: calc(100vh + 260px);
            z-index: 6;
            @include below("md") {
                height: 100vh;
            }
            pointer-events: none;
            position: absolute;
            bottom: 0;
            .top {
                width: 100%;
                height: 130px;
                position: relative;
                z-index: 5;
                transform: translateY(1px) rotate(180deg);
                @include below("md") {
                    display: none;
                }
                img {
                    position: absolute;
                    -o-object-fit: cover;
                    object-fit: cover;
                    @include below("xl") {
                        height: 100%;
                        max-height: 130px;
                    }
                    @include above("xxl") {
                        position: absolute;
                        top: -30px;
                    }
                }
            }
            .middle {
                width: 100%;
                height: 100vh;
                z-index: 6;
                position: relative;
                @include below("md") {
                    height: 100vh;
                }
                background: $main;
            }
            .bottom {
                width: 100%;
                height: 130px;
                position: relative;
                z-index: 5;
                transform: translateY(-1px);
                @include below("md") {
                    display: none;
                }
                img {
                    position: absolute;
                    -o-object-fit: cover;
                    object-fit: cover;
                    @include below("xl") {
                        height: 100%;
                        max-height: 130px;
                    }
                    @include above("xxl") {
                        position: absolute;
                        bottom: 0;
                    }
                    &.under {
                        transform: translateY(-10px);
                        transition: all 0.3s ease-in-out;
                    }
                }
            }
        }

        &-mobile-footer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: absolute;
            bottom: 32px;
            width: 100%;
            padding: 0 32px;

            @include above("md") {
                top: 50%;
                left: 32px;
                bottom: unset;
                transform: translateY(-50%);
                padding: 0;
            }
        }

        &-socials {
            @include above("md") {
                display: none;
                max-height: 130px;
            }
            @include above("xxxl") {
                max-height: 160px;
            }
            @include above("xxxxl") {
                max-height: 180px;
            }
            ul {
                li {
                    a {
                        display: flex;
                        flex-flow: row nowrap;
                        align-items: center;

                        .tick {
                            width: 6px;
                            height: 6px;
                            transition: 0.3s all ease-in-out;
                            -webkit-filter: brightness(0) invert(1);
                            filter: brightness(0) invert(1);
                        }
                        span {
                            margin: auto 4px auto 8px;
                            font-size: 15px;
                            color: white;
                        }
                        .arrow {
                            width: 10px;
                            height: 10px;
                            transition: 0.3s all ease-in-out;
                        }
                        @media (any-hover: hover) {
                            &:hover {
                                cursor: pointer;
                                .tick {
                                    -webkit-filter: brightness(0%);
                                    filter: brightness(0%);
                                }
                                .arrow {
                                    transform: translateY(-4px);
                                }
                            }
                        }
                    }
                    & + li {
                        margin-top: 8px;
                    }
                }
            }
        }

        &-lang {
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;
            @include above("md") {
                flex-flow: column nowrap;
            }
            a {
                position: relative;

                display: flex;
                justify-content: center;
                align-items: center;
                span {
                    font-size: 15px;
                    line-height: 1.3;
                    color: white;
                    margin-top: 4px;
                    transition: 0.5s all ease-in-out;
                }
                .circle {
                    position: absolute;
                    top: 0;
                    left: 0;
                    .path {
                        transition: 0.5s all ease-in-out;
                    }
                }
                &.fr {
                    width: 36px;
                    height: 36px;
                    .path {
                        stroke-dasharray: 110;
                        stroke-dashoffset: 110;
                    }
                }
                &.en {
                    width: 38px;
                    height: 38px;
                    .path {
                        stroke-dasharray: 114;
                        stroke-dashoffset: 114;
                    }
                }
                &.current {
                    .circle {
                        .path {
                            stroke-dashoffset: 0;
                            transition: 0.5s all ease-in-out;
                        }
                    }
                }
                @media (any-hover: hover) {
                    &:hover {
                        cursor: pointer;
                        span {
                            opacity: 0.5;
                            transition: 0.5s all ease-in-out;
                        }
                    }
                }
                & + button {
                    margin-left: 8px;
                }
                @include above("md") {
                    & + button {
                        margin-left: 0;
                        margin-top: 8px;
                    }
                }
            }
        }
        &-items {
            @include above("md") {
                margin-bottom: 10px;
            }
            ul {
                display: flex;
                flex-flow: column nowrap;
                justify-content: center;
                align-items: center;
                @include above("md") {
                    flex-flow: row nowrap;
                }
                li {
                    color: white;
                    position: relative;
                    &:before,
                    &:after {
                        content: "";
                        display: block;
                        width: calc(50% + 4px);
                        height: 10px;
                        background-color: $main;
                        position: absolute;
                        bottom: -10px;
                        z-index: 1;
                        transform-origin: bottom left;
                        transition: 0.3s all ease-in;
                    }
                    &:before {
                        left: -2px;
                        transform-origin: bottom left;
                    }
                    &:after {
                        right: -2px;
                        transform-origin: bottom right;
                    }
                    span {
                        font-family: $font-title;
                        font-size: 38px;
                        line-height: 1.1;
                        &:after {
                            content: "";
                            display: block;
                            width: 100%;
                            height: 10px;
                            background-size: auto;
                            background-repeat: no-repeat;
                            background-position: center;
                            position: absolute;
                        }
                    }
                    &.work {
                        span {
                            &:after {
                                background-image: url("../assets/menu/underline-work.svg");
                            }
                        }
                    }
                    &.about {
                        span {
                            &:after {
                                background-image: url("../assets/menu/underline-about.svg");
                            }
                        }
                    }
                    &.contact {
                        span {
                            &:after {
                                background-image: url("../assets/menu/underline-contact.svg");
                            }
                        }
                    }
                    & + li {
                        margin-top: 24px;
                    }
                    @include above("md") {
                        & + li {
                            margin-top: 0;
                            margin-left: 80px;
                        }
                    }
                    @media (any-hover: hover) {
                        &:hover {
                            cursor: pointer;
                            &:before,
                            &:after {
                                width: 0;
                            }
                        }
                    }
                    &.current {
                        &:before,
                        &:after {
                            width: 0;
                        }
                    }
                }
            }
        }
    }
}
