@use "sass:math";

.page-about {
  width: 100%;
  margin: 0 auto;
  font-family: "Epilogue", sans-serif;

  &__banner-container {
    position: relative;
    width: 100%;
    overflow-y: hidden;

    @include below("lg"){
      overflow-x: hidden;
      overflow-y: auto;
    }


    &-title {
      width: 100%;
      max-width: 1200px;
      margin: 0 auto;
      padding: 50px;
      height: calc(100vh - 200px);
      display: flex;
      justify-content: center;
      align-items: center;
      @include above("lg") {
        height: auto;
        min-height: 720px;
      }
      @include above("xxxxl") {
        max-width: 1640px;
      }
      &_inner {
        height: 100%;
        cursor: default;
        display: inline-flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        @include above("lg") {
          height: auto;
        }
        h1 {
          text-align: center;
          span {
            font-family: $font-title;
            font-size: 44px;
            line-height: 1.1;
            @include above("lg") {
              font-size: 60px;
              line-height: 1;
            }
            @include above("lg") {
              font-size: 120px;
              line-height: 1;
            }
          }
          .un {
            font-family: $font-title;
            display: inline-block;
            transform: translateY(-30px) skewY(-30deg) rotateX(90deg);
            &.appear {
              transform: none;
              transition: transform 1s ease-in-out;
            }
          }
          .tap {
            display: block;
            font-family: $font-base;
            font-size: 13px;
            margin-top: 16px;
            opacity: 0;
            @include above("lg") {
              display: none;
            }
            &.appear {
              opacity: 1;
              transition: opacity 0.5s ease-in-out 1.5s;
            }
          }
        }
      }
    }

    &-parallax {
      max-width: 1200px;
      height: 300px;
      position: relative;
      overflow: hidden;
      margin: 0 16px;
      @include above("sm") {
        height: 400px;
      }
      @include above("lg") {
        height: 500px;
      }
      @include above("lg") {
        height: 600px;
        &:before,
        &:after {
          content: "";
          display: block;
          width: var(--width, 5%);
          height: 100%;
          background-color: white;
          position: absolute;
          top: 0;
          z-index: 1;
          transition: 0.35s all ease-out;
        }
        &:before {
          left: 0;
          transform-origin: center left;
        }
        &:after {
          right: 0;
          transform-origin: center right;
        }
      }
      @include above("xl") {
        margin: 0 auto;
      }
      @include above("xxxxl") {
        max-width: 1640px;
      }
      img {
        width: auto;
        height: 120%;
        position: absolute;
        top: 5%;
        @include above("sm") {
          width: 110%;
          height: auto;
        }
        @include below("lg") {
          left: 50%;
          transform: translateX(-50%);
        }
        @include above("lg") {
          width: 115%;
        }
        @include above("lg") {
          width: 105%;
        }
      }
    }

    .object-img {
      width: 250px;
      position: absolute;
      pointer-events: none;
      z-index: 3;
      display: none;
      transform-origin: center center;
      &.odd {
        display: block;
        -webkit-animation: imgOddDisappear 1s ease-in;
        animation: imgOddDisappear 1s ease-in;
      }
      &.even {
        display: block;
        -webkit-animation: imgEvenDisappear 1s ease-in;
        animation: imgEvenDisappear 1s ease-in;
      }
    }
  }

  &__content-container {
    & > section {
      width: 100%;
      max-width: 1200px;
      margin: 0 auto;
      @include above("xxxxl") {
        max-width: 1640px;
      }
    }

    .section {
      width: 100%;
      display: flex;
      flex-flow: column nowrap;
      justify-content: space-between;
      align-items: stretch;
      padding: 24px 0;
      overflow: hidden;

      @include above("lg") {
        flex-flow: row nowrap;
      }
      &__title,
      &__description {
        width: 100%;
        padding: 0 16px;
        @include above("lg") {
          width: 50%;
        }
        @include above("xl") {
          padding: 0;
        }
      }
      &__title {
        @include above("lg") {
          padding-right: 36px;
        }

        h2 {
          font-family: $font-title;
          font-size: 38px;
          line-height: 1.1;

          @include below("lg") {
            font-size: 24px;
            line-height: 110%;
          }
        }
      }
      &__description {
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
        align-items: flex-start;
        @include above("lg") {
          padding-left: 36px;
        }
        &-text {
          font-size: 15px;
          line-height: 1.3;
        }
      }

      &__catch-phrase {
        .section__title {
          @include below("lg") {
            margin-bottom: 16px;
          }
        }
        .section__description {
          &-text {
            margin-top: 8px;
          }
          &-socials {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            @include below("lg") {
              margin-top: 16px;
              justify-content: flex-start;
            }
            ul {
              li {
                padding-right: 2px;
                a {
                  display: flex;
                  flex-flow: row nowrap;
                  align-items: center;

                  .tick {
                    width: 6px;
                    height: 6px;
                    path{
                      transition: 0.3s fill ease-in-out;
                      fill: $main;
                    }
                  }
                  span {
                    margin: auto 4px auto 8px;
                    font-size: 15px;
                  }
                  .arrow {
                    width: 10px;
                    height: 10px;
                    transition: 0.3s transform ease-in-out;

                    path{
                      stroke: #000;
                    }
                  }
                  @media (any-hover: hover) {
                    &:hover {
                      cursor: pointer;
                      .tick {
                        -webkit-animation: rotate infinite linear 3s;
                        animation: rotate infinite linear 3s;

                        path{
                          fill: #000;
                        }
                      }
                      .arrow {
                        transform: translate(2px, -2px);
                      }
                    }
                  }
                }
                & + li {
                  margin-top: 8px;
                }
              }
            }
          }
          @include below("lg") {
            margin-bottom: 48px;
          }
        }
      }

      &__bio {
        @include below("lg") {
          .section__title {
            &:before {
              background-image: url("../assets/details/accordion-line1.svg");
            }
          }
        }
      }

      &__process {
        flex-flow: column nowrap;
        max-width: 100%;

        .section__title {
          width: 100%;
          max-width: 1200px;
          margin: 0 auto;
          margin-bottom: 48px;
          @include above("xxxxl") {
            max-width: 1640px;
          }
        }

        @include below("lg") {
          .section__title {
            margin: 0;
            &:before {
              background-image: url("../assets/details/accordion-line2.svg");
            }
          }
        }
      }

      &__slider {
        width: 100%;

        .swiper {
          $enter-duration: 0.35s;
          $enter-ease: ease-in-out;

          &-slide {
            width: 304px + 56px;
            padding-left: 56px;

            &:first-child {
              width: 304px + 16px;
              padding-left: 16px;

              @media screen and (min-width: #{1200px + (16px) * 2}) {
                padding-left: calc((100vw - 1200px) / 2);
                width: calc(304px + (100vw - 1200px) / 2);
              }

              @include above("xxxxl") {
                padding-left: calc((100vw - 1640px) / 2);
                width: calc(304px + (100vw - 1640px) / 2);
              }
            }

            @include below("lg") {
              &,
              &:first-child {
                width: 250px + 20px;
                padding-left: 20px;
              }
            }

            &:last-child {
              width: 304px + 56px + 16px;
              padding-right: 16px;

              @include below("lg") {
                width: 250px + 20px + 20px;
                padding-right: 20px;
              }

              @media screen and (min-width: #{1200px + (16px) * 2}) {
                padding-right: calc((100vw - 1200px) / 2);
                width: calc(304px + 56px + (100vw - 1200px) / 2);
              }

              @include above("xxxxl") {
                padding-right: calc((100vw - 1640px) / 2);
                width: calc(304px + 56px + (100vw - 1640px) / 2);
              }
            }

            .section__slider-container {
              transition: transform $enter-ease $enter-duration, opacity 0s $enter-duration;
              opacity: 0;
            }

            &.swiper-slide-visible .section__slider-container {
              transform: translate(0, 0);
              opacity: 1;
              transition: transform $enter-ease $enter-duration;
            }

            &.left .section__slider-container {
              transform: translate(-100%, 0);
              transition: transform $enter-ease $enter-duration $enter-duration, opacity 0s $enter-duration;
            }

            &.right .section__slider-container {
              transform: translate(100%, 0);
              transition: transform $enter-ease $enter-duration $enter-duration, opacity 0s $enter-duration;
            }
          }
        }

        &-container {
        }

        &-image {
          margin-bottom: 8px;
          border: 4px solid $secondary;
        }

        &-header {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 8px;
        }

        &-icon {
          margin-right: 8px;

          img {
            width: auto;
          }
        }

        &-title {
          font-size: 15px;
          font-weight: 500;
          text-transform: uppercase;
        }

        &-text {
          font-size: 15px;
          line-height: 130%;
          text-align: center;
        }
      }

      &__exhibitions {
        @include below("lg") {
          .section__title {
            &:before {
              background-image: url("../assets/details/accordion-line3.svg");
            }
          }
          .section__description {
            position: relative;
            padding: 0;
            &:after {
              content: "";
              width: 100%;
              height: 8px;
              display: block;
              background-size: cover;
              background-repeat: no-repeat;
              position: absolute;
              bottom: 0;
              background-image: url("../assets/details/accordion-line4.svg");
            }
          }
        }
        .section__description {
          &-text {
            ul {
              padding: 0;
              li {
                list-style-type: none;
                .name {
                  font-weight: 500;
                  text-transform: uppercase;
                  & + .location {
                    margin-left: 5px;
                  }
                }
                .location {
                  font-size: 13px;
                }
              }
              &.covidland {
                margin: 24px 0;
                position: relative;
                #virus {
                  width: 80px;
                  height: 80px;
                  position: absolute;
                  top: 50%;
                  left: 0;
                  transform: translate(-50%, -50%);
                  z-index: -1;
                  img {
                    width: 100%;
                    height: 100%;
                  }
                }
              }
            }
          }
        }
      }

      & + .section {
        @include above("lg") {
          margin-top: 256px;
        }
      }

      &__bio,
      &__process,
      &__exhibitions {
        // margin: 0;
        padding: 0;
        input {
          display: none;
        }
        @include below("lg") {
          .section__title {
            position: relative;
            padding: 0;
            &:before {
              content: "";
              width: 100%;
              height: 8px;
              display: block;
              background-size: cover;
              background-repeat: no-repeat;
              position: absolute;
              top: 0;
            }
            h2 label {
              position: relative;
              width: 100%;
              height: 80px;
              display: block;
              padding: 0 16px;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              font-size: 24px;
              line-height: 1.1;
              &:after {
                content: "";
                width: 24px;
                height: 24px;
                display: block;
                background-image: url("../assets/details/arrow-down.svg");
                background-size: contain;
                position: absolute;
                top: 50%;
                right: 32px;
                transform: translateY(-50%);
                transition: 0.3s all ease-in-out;
              }
              &.is-open {
                &:after {
                  transform: translateY(-50%) rotate(180deg);
                  transition: 0.3s all ease-in-out;
                }
              }
            }
          }
          .section__description,
          .section__slider {
            max-height: 0;
            transition: 0.5s all ease-in-out;
          }
          input {
            &:checked {
              & + .section__description,
              & + .section__slider {
                max-height: 150vh;
                transition: 0.5s all ease-in-out;
                padding-bottom: 48px;
              }
            }
          }
        }
      }
      &__exhibitions {
        @include below("lg") {
          .section__description-text {
            padding: 0 16px;
          }
        }
      }
    }

    .footer {
      width: 100%;
      position: relative;
      margin-top: 48px;

      @include above("lg") {
        margin-top: 96px;
      }
      &-over {
        display: flex;
        justify-content: center;
        align-items: center;

        @include above("lg") {
          display: block;
          position: absolute;
          left: 8%;
          top: -40%;
          padding-left: 16px;
        }
        a {
          display: block;
          width: 96px;
          height: 96px;
          display: flex;
          justify-content: center;
          align-items: center;

          @include below("lg") {
            &.desktop {
              display: none;
            }
          }

          @include above("lg") {
            &.mobile {
              display: none;
            }
          }

          .blob.is-hover svg {
            transform: scale(0.9);
          }

          span {
            font-size: 13px;
            color: white;
            position: relative;
            z-index: 1;
          }
        }
      }
      &-under {
        h3 {
          position: static;
          transform: none;
          line-height: 1;
        }
      }
    }
  }
}

@-webkit-keyframes imgEvenDisappear {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  30% {
    transform: translateY(-30px) rotate(5deg);
  }
  90% {
    opacity: 1;
  }
  100% {
    transform: translateY(1024px) rotate(20deg);
    opacity: 0;
  }
}

@keyframes imgEvenDisappear {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  30% {
    transform: translateY(-30px) rotate(5deg);
  }
  90% {
    opacity: 1;
  }
  100% {
    transform: translateY(1024px) rotate(20deg);
    opacity: 0;
  }
}
@-webkit-keyframes imgOddDisappear {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  30% {
    transform: translateY(-30px) rotate(5deg);
  }
  90% {
    opacity: 1;
  }
  100% {
    transform: translateY(1024px) rotate(20deg);
    opacity: 0;
  }
}
@keyframes imgOddDisappear {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  30% {
    transform: translateY(-30px) rotate(-5deg);
  }
  90% {
    opacity: 1;
  }
  100% {
    transform: translateY(1024px) rotate(-20deg);
    opacity: 0;
  }
}

.text-appear-onscroll {
  @include above("lg") {
    .line-container {
      position: relative;
      overflow: hidden;
      span {
        display: block;
        transform: translateY(100%);
        opacity: 0;
        white-space: wrap;
      }
      &.is-inview {
        span {
          -webkit-animation: textAppear 0.5s ease-in forwards;
          animation: textAppear 0.5s ease-in forwards;
        }
      }
    }
  }
}

@include above("lg") {
  .exhi-appear-onscroll {
    position: relative;
    overflow: hidden;
    span {
      display: inline-block;
      transform: translateY(100%);
      opacity: 0;
      white-space: nowrap;
    }
    &.is-inview {
      span {
        -webkit-animation: textAppear 0.5s ease-in forwards;
        animation: textAppear 0.5s ease-in forwards;
      }
    }
  }
}

@for $i from 1 through 50 {
  .exhi-appear-onscroll:nth-child(#{$i}),
  .text-appear-onscroll:nth-child(#{$i}) {
    span {
      -webkit-animation-delay: 0.1s * math.div($i, 2);
      animation-delay: 0.1s * math.div($i, 2);
    }
  }
}

@-webkit-keyframes textAppear {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes textAppear {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@-webkit-keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
