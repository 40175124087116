.loader{
    @include absolute-full();
    position: fixed;
    z-index: 10;
    pointer-events: all;

    &__title{
        @include absolute-center();
        font-family: $font-title;
        font-weight: 400;
        font-size: 180px;
        color: $secondary;
        white-space: nowrap;
        width: 100%;
        text-align: center;
        transform: translate(-50%, -50%) scale(.81);
        transform-origin: center center;
        -webkit-user-select: none;
           -moz-user-select: none;
            -ms-user-select: none;
                user-select: none;
    }

    &__progress{
        position: absolute;
        left: 50%;
        bottom: 36px;
        transform: translate(-50%, 0);

        svg{
            width: 374px;
        }

        &-label{
            color: #fff;
            font-weight: 500;
            font-size: 15px;
            text-transform: uppercase;
            text-align: center;
            margin-bottom: 10px;
        }

        &-container{
            position: relative;
        }

        &-fixed{
        }

        &-moving{
            position: absolute;
            left: 0;
            top: 0;
            width: 0%;
            overflow: hidden;
        }
    }

    &__bg{
        @include absolute-full();
        height: auto;
        z-index: -1;

        &:before{
            content: "";
            display: block;
            height: 100vh;
            background-color: $main;
        }

        &-mask{
            fill: $main;
        }
    }

    @include below("lg"){
        &__progress{

            &-label{
                line-height: 130%;
            }

            &-label,
            svg{
                width: 200px;
            }
        }
    }
}