body.has-loader {
  @include above("lg"){
    .page-home {
      &__images {
        &-container {
          will-change: transform, opacity;
          opacity: 0;
          transform: translate(-50%, -50%) scale(.5);
  
          @include below("lg"){
            transform: scale(.5);
          }
        }
      }
    }
  }
}

body.has-loader.loader-leaving {
  @include above("lg"){
    .page-home {
      &__images {
        &-container {
          transition: opacity cubic-bezier(0.68, 0, 0.32, 1) .75s,
          transform cubic-bezier(0.68, 0, 0.32, 1) 0.75s;
          transition-delay: .75s;
          opacity: 1;
          transform: translate(-50%, -50%) scale(1);
  
          @include below("lg"){
            transform: scale(1);
          }
        }
      }
    }
  }
}

body.has-loader.loader-has-left {
  @include above("lg"){
    .page-home {
      &__images {
        &-container {
          transition: transform ease 0.35s;
          transform: translate(-50%, -50%) scale(1);
  
          @include below("lg"){
            transform: scale(1);
          }
        }
      }
    }
  }
}

.page-home {
  height: 100%;
  &__tooltip {
    @include hidden();
    position: absolute;
    left: 4px;
    top: 4px;
    z-index: 2;
    background: #eaeaea;
    border: 0.5px solid #cccbcc;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
    border-radius: 1px;
    font-size: 12px;
    color: #4d4d4d;
    padding: 4px 8px;
    pointer-events: none;
    line-height: 15px;
    height: 22px;

    &.visible {
      @include visible();
    }
  }

  // Images
  &__images {
    height: 100%;
    position: relative;
    touch-action: none;
    overflow: hidden;

    @include below("lg"){
      border: .1px transparent solid; // weird fix on mobile : scrollbar only appear when there is a border
    }

    @media screen and (max-width: 1024px) {
      overflow-y: scroll;
    }
    
    @include below("lg"){
      touch-action: auto;
      height: auto;
    }

    $transition-duration: 0.35s;
    $transition-ease: ease;

    &.is-dragging &-container {
      transform: translate(-50%, -50%) scale(0.9) !important;
    }

    // Filter
    &-filter {
      position: fixed;
      bottom: 24px;
      left: 50%;
      transform: translate(-50%, 0);
      z-index: 6;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      pointer-events: all;
      display: flex;
      align-items: center;
      pointer-events: none;

      &-content {
        display: flex;
        align-items: center;
        height: 40px;
        padding: 0 16px;
      }

      &-text {
        font-size: 15px;
        margin-right: 8px;
        white-space: nowrap;
        margin-top: 4px;
        pointer-events: none;
      }

      &-input {
        min-width: 44px;
        height: 100%;
        display: flex;
        align-items: center;
        pointer-events: all;
        cursor: pointer;
        
        svg {
          width: auto;
        }

        rect:nth-child(2) {
          transition: fill ease 0.35s;
        }

        rect:last-child {
          transition: x ease 0.35s;
        }
        
        &.active{
          rect:nth-child(2) {
            fill: $green;
          }
  
          rect:last-child {
            x: 22px;
          }
        }

      }

      &-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: -1;

        .mobile {
          display: none;
        }
      }



      @include below("lg") {
        &-bg {
          & > svg {
            display: none;
          }

          .mobile {
            display: block;
          }
        }

        &[data-size="long"] {
          height: 57px;
        }

        &-text {
          color: #000;
        }

        &[data-size="long"] & {
          &-text {
            white-space: normal;
            width: 165px;
            text-align: left;
            line-height: 130%;
            color: #000;
          }

          &-bg svg {
            width: 249px;
          }

          &-input {
            width: 40px;
          }
        }
      }
    }

    // Grid
    &-container {
      display: inline-block;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      transition: transform ease 0.6s;
      will-change: transform;

      @include below("lg"){
        transform-origin: top center;
        transform: scale(1);
        left: 0;
        top: 0;
        margin: 0 1vw;
        position: static;
      }
    }

    &-draggable{
      @include below("lg"){
        padding-top: 48px;
        margin-bottom: 200px;
      }
    }

    &-item {
      position: absolute;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      cursor: pointer;

      &-image {
        height: 100%;

        &,
        img {
          transition: $transition-ease $transition-duration;
        }

        img {
          pointer-events: none !important;
          margin: 0 auto;
          width: auto;
          height: 100%;
          display: block;
          touch-action: none;

          @include below("lg"){
            touch-action: auto;
          }
        }
      }

      &-info {
        @include hidden();
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: 0;
        width: 100%;
        transform: translate(0, 25%);
        transition: $transition-ease $transition-duration;
        transition-property: transform, opacity, visibility;

        &-dim {
          margin-left: 8px;
          font-size: 15px;
          line-height: 130%;
        }
      }

      @include above("lg") {
        &:hover & {
          &-info {
            @include visible();
            transform: translate(0, 0);
          }

          &-image {
            height: calc(22vw - 44px);
          }
        }
      }

      @include below("lg") {
      
        &-info {
          @include visible();
          transform: translate(0, 0);
          flex-direction: column;
          position: static;

          .box-available {
            margin-bottom: 4px;
          }
        }

        &-image {
          height: auto;

          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }
  }


  @include below("lg"){
    .bg-name-title{
      display: none;
    }
  }
}
