.slider-cursor{
    @include absolute-full();
    position: fixed;
    z-index: 5;
    pointer-events: none;
    overflow: hidden;

    $transition-duration: .2s;
    $transition-offset: .15s;
    $transition-ease: ease;

    &-container{
        display: inline-block;
    }

    &-dot{
        position: relative;
        display: inline-block;
        transform: translate(-50%, -50%);
    }
    
    &-icon{
        position: relative;

    }

    .blob{
        z-index: -1;
        opacity: 0;
        transform: scale(.65);
        transition: $transition-ease $transition-duration $transition-offset;
        transition-property: transform, opacity;
    }

    &-arrow,
    &-label
    {
        opacity: 0;
        transition: opacity $transition-ease $transition-duration
    }

    &-arrow{
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
    }

    &-label{
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 26px;
        font-size: 13px;
        line-height: 13px;
        text-transform: lowercase;
        color: #fff;
        z-index: 1;

        &.multidirection{
            top: 17px;
        }
    }

    &.active &{
        
        &-arrow,
        &-label
        {
            opacity: 1;
            transition: opacity $transition-ease $transition-duration .15s;
        }
    }

    &.active{
        
        .blob{
            transform: scale(1);
            opacity: 1;
            transition: $ease-elastic-out $transition-ease;
            transition-property: transform, opacity;
            transition-duration: $transition-duration * 1.5, $transition-duration;
            transition-delay: $transition-duration * .2, 0s;
        }
    }
}