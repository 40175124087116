@use "sass:math";
@use "sass:map";

.page-product-single {
  padding-bottom: 40px;
  padding-top: 128px;

  @include above("lg") {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column wrap;
    height: 100%;
  }

  &.is-ready &__pagination {
    pointer-events: auto;
  }

  &__pagination {
    position: absolute;
    left: 50%;
    top: 32px;
    transform: translate(-50%, 0);
    width: 1024px;
    max-width: calc(100% - 32px);

    @include above("xxxxl") {
      width: 100%;
      max-width: 1640px;
    }
    display: flex;
    align-items: center;
    z-index: 2;
    height: 56px;
    pointer-events: none;

    display: flex;
    align-items: center;
    justify-content: flex-end;

    &.prev_product {
      justify-content: space-between;
    }

    &-link {
      font-size: 13px;
      text-transform: lowercase;

      @include above("lg") {
        &:hover & {
          &-label {
            transform: translate(0, -2px);
          }

          &-icon {
            transform: translate(0, 2px);
          }
        }
      }

      &-label,
      &-icon {
        transition: transform ease-out 0.2s;
        will-change: transform;
      }

      &-icon {
        width: 29px;
      }
    }

    &-back {
      position: absolute;
      left: 50%;
      top: 0;
      transform: translate(-50%, 0);

      @include above("lg") {
        &:hover &-blob {
          transform: translate(-50%, -50%) scale(1.2);
        }
      }

      &-blob {
        @include absolute-center();
        position: absolute !important;
        z-index: -1;
        transition: transform ease 0.35s;

        svg.blob__svg path {
          fill: #fff;
          stroke-width: 1px;
          stroke: #000;
        }
      }

      &-label {
        position: absolute;
        font-size: 13px;
        left: 50%;
        top: 32px;
        transform: translate(-50%, 5px);
        text-transform: lowercase;
      }
    }
  }

  &__slider,
  &__content {
    width: 1024px;
    max-width: calc(90vh * #{math.div(1024px, 683px)} - 250px);
    margin: 0 auto;

    @include above("xxxxl") {
      max-width: calc(90vh * #{math.div(1200px, 683px)} - 500px);
      width: 1400px;
    }

    @media screen and (min-width: map-get($grid-breakpoints, "lg")) and (max-width: map-get($grid-breakpoints, "xl")) and (orientation: portrait) {
      max-width: 90vw;
    }
  }

  &__slider {
    position: relative;

    &:before {
      content: "";
      display: block;
      width: 100%;
      height: 0px;
      padding-bottom: math.div(1, math.div(1024, 683)) * 100%;
    }

    &-mask {
      @include absolute-full();
      z-index: 2;
      overflow: hidden;
      pointer-events: none;

      svg {
        width: 101%;
        fill: #fff;
        shape-rendering: crispEdges;
      }
    }

    .swiper {
      margin: 0 auto;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;

      &-pagination {
        position: absolute;
        bottom: 8px;
        left: 50%;
        transform: translate(-50%, 0);
        z-index: 1;
        display: flex;

        &-bullet {
          width: 8px;
          height: 8px;

          &:not(:last-child) {
            margin-right: 8px;
          }

          svg {
            fill: $main;
            transition: fill ease 0.35s;
          }

          &-active svg {
            fill: #000;
          }
        }
      }
    }
  }

  &__content {
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    margin-top: 16px;

    &-left {
      width: 80%;
    }

    &-title {
      font-size: 15px;
      font-weight: 500;
      line-height: 115%;
      text-transform: uppercase;
      word-break: break-word;
    }

    &-dimension {
      font-size: 13px;
    }
  }

  @include below("lg") {
    display: flex;
    flex-direction: column;
    padding-bottom: 100px;
    padding-top: 0;

    &__pagination {
      position: fixed;
      top: auto;
      bottom: 24px;
      transform: translate(-50%, 0);
    }

    &__content {
      order: 0;
      padding-top: 16px;

      &-left {
        @include below("lg") {
          width: 70%;
        }
      }
    }

    &__slider {
      order: 1;
      padding-top: 22px;

      &::before {
        content: none;
        display: none;
      }

      .swiper {
        &-wrapper {
          display: block;
        }

        &-slide {
          &:not(:last-child) {
            margin-bottom: 16px;
          }
        }
      }
    }

    &__content,
    &__slider {
      max-width: 100%;
      padding-left: 16px;
      padding-right: 16px;

      .swiper {
        position: static;
      }
    }
  }
}
