body.has-loader.loader-leaving {
    .page-404 {
        .bg-name-title {
            animation: bounce 1s ease-in 1.6s forwards;
        }
    }
}

body.has-loader.loader-has-left {
    // .page-404 {
    //   .bg-name-title {
    //     bottom: 0;
    //     animation: bounce 1s ease-in-out;
    //   }
    // }
}

@keyframes bounce {
    0% {
        bottom: 40%;
    }
    50% {
        bottom: 0;
    }
    75% {
        bottom: 3%;
    }
    100% {
        bottom: 0;
    }
}

.page-404 {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;

    .bg-name-title {
        top: unset;
        bottom: 40%;
        transform: translate(-50%, 0);
        line-height: 0.76;
        @include below("md") {
            display: none;
        }
    }

    &_content {
        z-index: 1;
    }
    &_title {
        font-size: 24px;
        line-height: 1.1;
        font-family: $font-title;
        text-align: center;
        @include above("lg") {
            font-size: 38px;
        }
    }
    &_subtitle {
        text-align: center;
        font-size: 44px;
        line-height: 1;
        font-family: $font-title;
        margin: 32px 16px;
        @include above("lg") {
            font-size: 120px;
        }
        &.fr {
            max-width: 1100px;
        }
        &.en {
            max-width: 900px;
        }
    }
    .btn-container {
        display: flex;
        justify-content: center;
        align-items: center;

        a {
            width: 96px;
            height: 96px;
            display: flex;
            flex-flow: column nowrap;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            @include below("lg") {
                &.desktop {
                    display: none;
                }
            }

            @include above("lg") {
                &.mobile {
                    display: none;
                }
            }

            .blob.is-hover svg {
                transform: scale(0.9);
            }

            span {
                font-size: 13px;
                color: white;
                position: relative;
                text-align: center;
                z-index: 1;
                line-height: 1.5;
                &.fr {
                    max-width: 90px;
                }
                &.en {
                    max-width: 67px;
                }
            }
        }
    }
}
